import React from "react";
import Navigation from "../Layout/Navigation";
import PrimaryButton from "../components/form/PrimaryButton";
import RestaurantClosedImg from "../assets/restaurant_closed_1.png";
import { useNavigate } from "react-router-dom";

export default function TakeABreakPage() {
    const navigate = useNavigate();

    return (
        <div>
            <Navigation />

            <div className="h-[80vh] grid place-items-center w-full">
                <section className="grid px-6 mt-20 md:px-0 place-items-center w-full">
                    <img className="w-full md:w-[20%]" src={RestaurantClosedImg} alt="closed restaurant img tasteclan" />
                    {/* <p className="font-bold text-[28px] text-gray-600 md:text-[40px]">We took a break for you to deliver</p> */}

                    <p className="text-gray-600 mt-3">{"We took a break, we will be back soon"}</p>
                    <div className="w-[300px] mt-10 h-[50px]">
                        <PrimaryButton handle={() => {
                            navigate("/")
                        }} className="p-3 font-semibold">Home Page</PrimaryButton>
                    </div>
                </section>
            </div>
        </div>
    )
}