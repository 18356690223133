import React from "react";
//import  { useContext } from 'react';
// import { Navigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
//import AuthContext from "../contexts/AuthContext";

const AuthenticatedRoutes = () => {
  // auth context
  //const { accessToken } = useContext(AuthContext);
  // const accessToken = ""

  // if (accessToken) {
  //   return <Outlet />;
  // }
  return <Outlet />;

  // return (
  //   <>
  //     <Navigate to="/" />
  //   </>
  // );
};

export default AuthenticatedRoutes;
